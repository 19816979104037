<template>
  <div class="BannerCampaignNotFound">
    {{ $t('banner.campaignNotFound.title') }}
    <ha-button
      class="Close"
      data-ux="Explore_OrganizationPublicPage_BannerCampaignNotFound_HideBanner"
      @click="$emit('close')"
    >
      <HaIcon :icon="faXmark" />
      <span class="visually-hidden">
        {{ $t('banner.campaignNotFound.hideBanner') }}
      </span>
    </ha-button>
  </div>
</template>

<script setup>
import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import { HaButton, HaIcon } from '@ha/components-v3'
import { useI18n } from '#imports'

const i18n = useI18n()

defineEmits(['close'])
</script>

<style lang="scss" scoped>
.BannerCampaignNotFound {
  position: relative;
  padding: $ha-spacing-small $ha-spacing-jumbo;
  color: var(--ha-color-white);
  font-weight: $ha-font-weight-semibold;
  font-size: $ha-font-size-small;
  text-align: center;
  background-color: var(--ha-color-primary);

  .Close {
    position: absolute;
    top: $ha-unit * 0.5; // 4px
    right: $ha-unit * 0.5; // 4px
    min-width: $ha-unit * 4; // 32px
    padding: $ha-spacing-tiny;

    @include mediaQuery(600) {
      top: 50%;
      right: $ha-unit * 1.5; // 12px
      transform: translate3d(0, -50%, 0);
    }

    &:focus {
      box-shadow: 0 0 0 ($ha-unit * 0.5) set-alpha('white', 0.25); // 0 0 0 4px
    }
  }
}
</style>
