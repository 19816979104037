<template>
  <div class="Organization">
    <BannerCampaignNotFound
      v-if="displayBannerCampaignNotFound"
      @close="displayBannerCampaignNotFound = false"
    />
    <organization />
  </div>
</template>

<script>
import { useRoute } from '#imports'
import { useTracking } from '@ha/components-v3'
import BannerCampaignNotFound from '@/components/Banner/BannerCampaignNotFound.vue'
import Organization from '@/components/Organization/Organization.vue'
import useConfigEnv from '@/composables/useConfigEnv'
import { useOrganizationStore } from '@/store/organization.store'

export default {
  name: 'OrganizationPage',
  components: {
    BannerCampaignNotFound,
    Organization
  },
  setup() {
    const { tracking } = useTracking()
    const configEnv = useConfigEnv()
    const route = useRoute()
    const organizationStore = useOrganizationStore()

    if (import.meta.client) {
      tracking?.page({
        rubrik: 'Explore',
        context: 'Contributeur',
        category: 'Page Asso',
        title: 'Contributeur | HelloAsso',
        organization_slug: route.params.organization,
        version: configEnv.PACKAGE_VERSION
      })
    }

    return { configEnv, organizationStore }
  },
  data() {
    return {
      displayBannerCampaignNotFound:
        this.$route.fullPath.endsWith('?banner=True')
    }
  },
  computed: {
    organization() {
      return this.organizationStore.organization
    }
  },
  jsonld() {
    let phoneNumber = this.organization?.contact?.phoneNumber
    if (phoneNumber && phoneNumber.charAt(0) === '0') {
      phoneNumber = `+33${phoneNumber.substring(1, phoneNumber.length - 1)}`
    }
    const jsonLd = {
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      '@id': `${this.configEnv.NUXT_ENV_BASE_URL}/associations/${this.organization.organizationSlug}`,
      name: this.organization.name,
      url: `${this.configEnv.NUXT_ENV_BASE_URL}/associations/${this.organization.organizationSlug}`,
      telephone: phoneNumber || '-', // "+33{n° de tel de l'asso (sans le 0)}"
      nonprofitStatus: 'À but non lucratif',
      priceRange: '-'
    }

    if (this.organization?.city || this.organization?.address) {
      jsonLd.address = {
        '@type': 'PostalAddress',
        addressLocality: this.organization.city,
        streetAddress: this.organization.address,
        postalCode: this.organization.zipCode,
        addressCountry: 'FR'
      }
    }

    jsonLd.image =
      this.organization?.banner ||
      this.organization?.logo ||
      'https://cdn.helloasso.com/documents/brand/logo-helloasso-bleu.svg'

    return jsonLd
  }
}
</script>
